.role-toggle-container {
  width: 72px;
  height: 43px;
  background-color: gray;
  border-radius: 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.role-toggle-container.role-toggled {
  background-color: #1355ff;
}

.role-toggle-button {
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.role-toggle-button.role-toggled {
  left: 40px;
  background-color: #ffffff;
}
