/* CardSlider.css */

/* Other existing styles */

.card-slider {
  padding: 15px;
}

.slick-list {
  overflow: hidden;
}

.slick-track {
  display: flex;
  justify-content: start;
  margin-left: 0;
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.slick-prev,
.slick-next {
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: -5px;
}

.slick-next {
  right: -5px;
}

.slick-prev img,
.slick-next img {
  width: 30px;
  height: 30px;
}

/* Customize dots color */
.slick-dots li button:before {
  color: blue; /* Change dot color */
}

.slick-dots li.slick-active button:before {
  color: blue; /* Change active dot color */
}

/* Optional: Adjust size of dots if needed */
.slick-dots li button:before {
  font-size: 12px; /* Adjust size as needed */
}

.slick-slide.slick-active.slick-current {
  display: flex;
  justify-content: center;
}
