/* Add this CSS to your global styles */
.toggle-checkbox:checked {
    right: 0;
  }
  
  .toggle-checkbox {
    right: 1.5rem; /* Adjust the initial position */
  }
  
  .toggle-label {
    background-color: #4a90e2;
  }
  