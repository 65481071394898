/* Style for the calendar popper */
.custom-datepicker-popper {
  .react-datepicker__triangle {
    display: none; /* Hide the triangle */
  }

  .react-datepicker__header {
    background-color: #e7eeff;
    border-bottom: 1px solid #000;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #eee;
    color: #fff;
  }

  .react-datepicker__day--today {
    font-weight: bold;
    color: #1355ff;
  }

  .react-datepicker__day {
    color: #27346a;

    &:hover {
      background-color: #e7eeff;
    }
  }
}
